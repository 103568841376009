<template>
  <div>
    <b-modal
      id="client-email-modal"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t(`${$t('SendEmailto')} ${sendToModalObject ? sendToModalObject.firstName : ''} ${sendToModalObject ? sendToModalObject.lastName: ''}`)"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>

        <b-form-group
          id="input-group-1"
          :label="`${$t('Occasion')}:`"
          label-for="input-1"
        >
          <vue-select
            id="input-5"
            v-model="form.ocassion"
            :reduce="(e) => e.value"
            required
            :options="options"
            label="label"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <b-form-group
          v-if="form.ocassion == 'other'"
          id="input-group-1"
          :label="`${$t('Other')}:`"

          label-for="input-1"
        >
          <b-form-input
            id="input-1"

            v-model="otherOccasion"
            aria-describedby="input-1-live-feedback"
            type="text"
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Discount')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.discount"

            aria-describedby="input-1-live-feedback"
            type="number"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <pre />
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            style="margin-right: 15px; background: white; color: black; border-color: white; font-weight: 400;"
            class="buttonCancel"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types

  props: ['sendToModalObject'],
  data() {
    return {
      options: [
        { value: 'Wedding', label: this.$t('Wedding') },
        { value: 'Engagement', label: this.$t('Engagement') },
        { value: 'IndependenceDay', label: this.$t('IndependenceDay') },
        { value: 'EidMubarak', label: this.$t('EidMubarak') },
        { value: 'Christmas', label: this.$t('Christmas') },
        { value: 'NewYear', label: this.$t('NewYear') },

      ],
      form: {
        ocassion: '',
        discount: null,
      },
      otherOccasion: null,

    };
  },
  computed: {
    ...mapGetters(['getfreeTeamLeaders', 'getVacationRequests']),

  },

  watch: {
    birthday(value) {
      if (value !== 'other') {
        this.otherOccasion = null
      }
    },
    // otherOccasion(value) {
    //   this.options.push({ value, name: value })
    // },
  },
  methods: {
    ...mapActions(['loadUsersFullNamesById', 'cancelVacation']),
    async onSubmit() {
      // console.log(this.form)
      this.$emit('sendEmail', this.form);
      this.$refs.modal.hide()
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.form.discount = '';
      this.form.ocassion = ''
    },

  },
};
</script>

<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
