<template>
  <div >
    <section
      class="main no-flex"
      style="margin-top: 0px;"
    >

      <UsersListing :material-id="materialIdToShow" />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import UsersListing from '@/components/teams/UsersListing.vue'
import { mapGetters } from 'vuex'


export default {
  name: 'Home',
  components: {
    UsersListing,
  },
  data() {
    return {
      materialIdToShow: '',
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
  methods: {
    materialId(id) {
      this.materialIdToShow = id
    },
  },
}
</script>

<style scoped lang="scss">

@media screen and (max-width: 1600px){
  .main{
    padding: 75px 15px 15px 90px;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}

@media screen and (max-width: 1200px){
  .main{
    padding: 75px 15px 15px 10px;
  }
}
</style>
