
<template>
  <div>
    <b-modal
      id="view-user"
      ref="modal"
      header-class="headerModal"
      :title="`${formUser.editUserRequestDto.firstName} ${formUser.editUserRequestDto.lastName} ${$t('Details')} `"
      centered
      size="lg"
      hide-footer
      no-close-on-backdrop
      @close="onCancel"
    >
      <b-tabs
        v-model="tabIndex"
        card
      >
        <b-tab
          :title="`${$t('UserInformation')} `"
          active
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("UserInformation") }}</strong>
          </template>
          <b-card-text><b-form disabled>
            <b-form-group
              id="input-group-1"
              :label="`${$t('Salutation')}:`"
              label-for="input-1"
            >
              <vue-select
                v-model="formUser.editUserRequestDto.salutation"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('FirstName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="formUser.editUserRequestDto.firstName"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('LastName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="formUser.editUserRequestDto.lastName"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-2"
              :label="`${$t('Gender')}:`"
              label-for="input-2"
            >
              <vue-select
                v-model="formUser.editUserRequestDto.gender"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('DateofBirth')}:`"
              label-for="input-9"
            >
              <b-form-input
                v-model="formatedDate"
                class="form-control"
                :placeholder="$t('DateofBirth')"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('PhoneNumber')}:`"
              label-for="input-9"
            >
              <vue-phone-number-input
                v-model="formUser.editUserRequestDto.phoneNumber"
                :default-country-code="formUser.editUserRequestDto.countryCode"
                :preferred-countries="['AL', 'CH', 'DE']"
                :no-example="true"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('Email')}:`"
              label-for="input-9"
            >
              <b-form-input
                id="input-9"
                v-model="formUser.editUserRequestDto.email"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('Role')}:`"
              label-for="input-9"
            >
              <vue-select
                v-model="formUser.editUserRequestDto.role"
                disabled
              />
            </b-form-group>
            <b-form-group
              v-show="getLoggedInUser.role === 'CEO' || getLoggedInUser.role === 'Admin' || getLoggedInUser.role === 'HRFinance'"
              id="input-group-9"
              :label="`${$t('Salary')}:`"
              label-for="input-9"
            >
              <b-form-input
                id="input-9"
                v-model="formUser.editUserRequestDto.salary"
                disabled
              />
            </b-form-group>
          </b-form>
          </b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Address") }}</strong>
          </template>
          <b-card-text>
            <b-form>
              <b-form-group
                id="input-group-3"
                :label="`${$t('Street')}:`"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="formUser.editUserRequestDto.street"
                  disabled
                />
              </b-form-group>

              <b-form-group
                id="input-group-6"
                :label="`${$t('Door/HouseNumber')}:`"
                label-for="input-6"
              >
                <b-form-input
                  id="input-6"
                  v-model="formUser.editUserRequestDto.doorNumber"
                  disabled
                />
              </b-form-group>
              <b-form-group
                id="input-group-6"
                :label="`${$t('PostalCode')}:`"
                label-for="input-6"
              >
                <b-form-input
                  id="input-6"
                  v-model="formUser.editUserRequestDto.postalCode"
                  disabled
                />
              </b-form-group>
              <b-form-group
                id="input-group-5"
                :label="`${$t('City')}:`"
                label-for="input-5"
              >
                <b-form-input
                  id="input-5"
                  v-model="formUser.editUserRequestDto.city"
                  disabled
                />
              </b-form-group>
              <b-form-group
                id="input-group-7"
                :label="`${$t('Country')}:`"
                label-for="input-7"
              >
                <b-form-input
                  id="input-7"
                  v-model="formUser.editUserRequestDto.country"
                  disabled
                />
              </b-form-group>
            </b-form>
          </b-card-text>
        </b-tab>
        <b-tab
          v-if="client != 'NotaBrillant'"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("EmployeeDocuments") }}</strong>
          </template>
          <div>
            <b-form-group
              id="input-group-6"
              style="font-weight: bold"
              class="mb-0"
            />
            <div
              v-if="imagePassport.length > 0"
            >
              <div class="files">
                <span style="color: #262E6C; font-weight: bold;">{{ $t("Passport") }}</span>
                <div
                  style="display: flex; flex-direction: row !important;"
                >
                  <div
                    v-for="(img, index) in imagePassport"
                    :key="img.photoId"
                    :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
                    style="background-color: transparent; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;gap: 20px;"
                  >
                    <template v-if="img.src.includes('.png') || img.src.includes('.jpeg') || img.src.includes('.webp')">
                      <img
                        :src="img.src"
                        :class="{'photo': true, 'hovered': hoveredIndex === img.photoId, 'second-photo': index === 1, 'third-photo': index === 2}"
                        :style="{ transform: `scale(${img.zoom})` }"
                        alt="Not an image"
                        @click="openLightbox(index)"
                      >
                      <button
                        style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                        @click="downloadFile(img.src)"
                      >
                        <b-icon-download style="color: white;" />
                      </button>
                    </template>
                    <template v-else>
                      <img
                        v-b-tooltip="$t('DocumentOrPhoto')"
                        style="width:40px;height:40px"
                        src="../../../assets/images/documentLogo.svg"
                      >
                      <button
                        v-if="img.src.length > 0"
                        v-b-tooltip="$t('Download')"
                        style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                        @click.stop="downloadFile(img.src)"
                      >
                        <b-icon-download style="color: #FF274F;" />
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="files"
            >
              <span style="color: #262E6C; font-weight: bold;">{{ $t("No Passport") }}</span>
            </div>
            <div
              v-if="imageResidencePermit.length > 0"
            >
              <div class="files">

                <span style="color: #262E6C; font-weight: bold;">{{ $t("Contract") }}</span>


                <div
                  v-for="(img, index) in imageResidencePermit"
                  :key="img.photoId"
                  :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
                  style="background-color: transparent; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;gap: 20px;"
                >
                  <template v-if="img.src.includes('.png') || img.src.includes('.jpeg') || img.src.includes('.webp')">
                    <img
                      :src="img.src"
                      :class="{'photo': true, 'hovered': hoveredIndex === img.photoId, 'second-photo': index === 1, 'third-photo': index === 2}"
                      :style="{ transform: `scale(${img.zoom})` }"
                      alt="Not an image"
                      @click="openLightbox2(index)"
                    >
                    <button
                      style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                      @click="downloadFile(img.src)"
                    >
                      <b-icon-download style="color: white;" />
                    </button>
                  </template>
                  <template v-else>
                    <img
                      v-b-tooltip="$t('DocumentOrPhoto')"
                      style="width:40px;height:40px"
                      src="../../../assets/images/documentLogo.svg"
                    >
                    <button
                      v-if="img.src.length > 0"
                      v-b-tooltip="$t('Download')"
                      style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                      @click.stop="downloadFile(img.src)"
                    >
                      <b-icon-download style="color: #FF274F;" />
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <div
              v-else
              class="files"
            >
              <span style="color: #262E6C; font-weight: bold;">{{ $t("No Contract") }}</span>
            </div>
            <div v-if="imageSocialNumber.length > 0">
              <div
                style="display: flex; flex-direction: row !important;"
              >
                <div class="files">
                  <span style="color: #262E6C; font-weight: bold;">{{ $t("CV") }}</span>


                  <div
                    v-for="(img, index) in imageSocialNumber"
                    :key="img.photoId"
                    :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
                    style="background-color: transparent; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;gap: 20px;"
                  >
                    <template v-if="img.src.includes('.png') || img.src.includes('.jpeg') || img.src.includes('.webp')">
                      <img
                        :src="img.src"
                        :class="{'photo': true, 'hovered': hoveredIndex === img.photoId, 'second-photo': index === 1, 'third-photo': index === 2}"
                        :style="{ transform: `scale(${img.zoom})` }"
                        alt="Not an image"
                        @click="openLightbox1(index)"
                      >
                      <button
                        style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                        @click="downloadFile(img.src)"
                      >
                        <b-icon-download style="color: white;" />
                      </button>
                    </template>
                    <template v-else>
                      <img
                        v-b-tooltip="$t('DocumentOrPhoto')"
                        style="width:40px;height:40px"
                        src="../../../assets/images/documentLogo.svg"
                      >
                      <button
                        v-if="img.src.length > 0"
                        v-b-tooltip="$t('Download')"
                        style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                        @click.stop="downloadFile(img.src)"
                      >
                        <b-icon-download style="color: #FF274F;" />
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="files"
            >
              <span style="color: #262E6C; font-weight: bold;">{{ $t("No CV") }}</span>
            </div>
          </div>
          <vue-easy-lightbox
            :visible="lightboxVisible"
            :index="lightboxIndex"
            :imgs="imagePassport"
            @hide="closeLightbox"
          />
          <vue-easy-lightbox
            :visible="lightboxVisible1"
            :index="lightboxIndex1"
            :imgs="imageSocialNumber"
            @hide="closeLightbox1"
          />
          <vue-easy-lightbox
            :visible="lightboxVisible2"
            :index="lightboxIndex2"
            :imgs="imageResidencePermit"
            @hide="closeLightbox2"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("EmergencyContact") }}</strong>
          </template>
          <b-form disabled>
            <b-form-group
              id="input-group-1"
              :label="`${$t('FirstName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="formUser.editUserRequestDto.emergencyContactDto.firstName"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('LastName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="formUser.editUserRequestDto.emergencyContactDto.lastName"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('Relation')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="formUser.editUserRequestDto.emergencyContactDto.relationship"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('PhoneNumber')}:`"
              label-for="input-9"
            >
              <vue-phone-number-input
                v-model="formUser.editUserRequestDto.emergencyContactDto.phoneNumber"
                :default-country-code="formUser.editUserRequestDto.emergencyContactDto.countryCode"
                :preferred-countries="['AL', 'CH', 'DE']"
                :no-example="true"
                disabled
              />
            </b-form-group>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import flatPickr from 'vue-flatpickr-component';
import { validationMixin } from 'vuelidate';
import moment from 'moment'
// import AWS from 'aws-sdk';

import VueEasyLightbox from 'vue-easy-lightbox';
import { AWSurl, client } from '../../../domainConfig';


// or


export default {
  components: {
    // flatPickr,
    VueEasyLightbox,
  },
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editUser: {
      type: Object,
    },
  },
  data() {
    return {
      client: client.clientName,
      visible: false,
      userId: null,
      formUser: {
        editUserRequestDto: {
          userId: '',
          firstName: '',
          lastName: '',
          salutation: '',
          gender: '',
          personRole: '',
          countryCode: '',
          phoneNumber: '',
          email: '',
          role: '',
          salary: '',
          street: '',
          doorNumber: '',
          postalCode: '',
          socialNumber: '',
          city: '',
          country: '',
          dateOfBirth: '',
          maritalStatus: '',
          residencePermit: '',
          nationality: '',
          employeeType: '',
          emergencyContactDto: {
            firstName: '',
            lastName: '',
            countryCode: '',
            relationship: '',
            phoneNumber: '',
          },
        },
      },
      imagePassport: [],
      imageSocialNumber: [],
      imageResidencePermit: [],
      lightboxVisible: false,
      lightboxIndex: 0,
      lightboxVisible1: false,
      lightboxIndex1: 0,
      lightboxVisible2: false,
      lightboxIndex2: 0,
      isTextShown: false,
      hoveredIndex: null,
      // text: 'Click to zoom the photo',
      tabIndex: 1,
      s3: null,
      bucketName: AWSurl.bucketName,

    };
  },
  computed: {
    ...mapGetters([
      'getLoggedInUser',

      'getFullUsersById',

    ]),
    formatedDate() {
      const data = moment(this.formUser.editUserRequestDto.dateOfBirth)
      return data.format('DD-MM-YYYY')
    },
    userById() {
      return this.$store.getters.getFullUsersById;
    },
  },
  watch: {
    userById: {
      handler(newVal) {
        console.log('hellooo', newVal)
        this.formUser.editUserRequestDto = {
          userId: newVal.userResponseDto.userId,
          firstName: newVal.userResponseDto.firstName,
          lastName: newVal.userResponseDto.lastName,
          salutation: newVal.userResponseDto.salutation,
          gender: newVal.userResponseDto.gender,
          personRole: newVal.userResponseDto.personRole,
          countryCode: newVal.userResponseDto.countryCode,
          phoneNumber: newVal.userResponseDto.phoneNumber,
          email: newVal.userResponseDto.email,
          role: newVal.userResponseDto.role,
          salary: newVal.userResponseDto.salary,
          street: newVal.userResponseDto.street,
          doorNumber: newVal.userResponseDto.doorNumber,
          postalCode: newVal.userResponseDto.postalCode,
          city: newVal.userResponseDto.city,
          country: newVal.userResponseDto.country,
          socialNumber: newVal.userResponseDto.socialNumber,
          maritalStatus: newVal.userResponseDto.maritalStatus,
          residencePermit: newVal.userResponseDto.residencePermit,
          nationality: newVal.userResponseDto.nationality,
          employeeType: newVal.userResponseDto.employeeType,
          dateOfBirth: newVal.userResponseDto.dateOfBirth,
          emergencyContactDto: {
            firstName: newVal.userResponseDto.emergencyContactDto ? newVal.userResponseDto.emergencyContactDto.firstName : null,
            lastName: newVal.userResponseDto.emergencyContactDto ? newVal.userResponseDto.emergencyContactDto.lastName : null,
            relationship: newVal.userResponseDto.emergencyContactDto ? newVal.userResponseDto.emergencyContactDto.relationship : null,
            phoneNumber: newVal.userResponseDto.emergencyContactDto ? newVal.userResponseDto.emergencyContactDto.phoneNumber : null,
            contryCode: newVal.userResponseDto.emergencyContactDto ? newVal.userResponseDto.emergencyContactDto.contryCode : null,
          },
        }

        // create an object to store arrays based on documentType
        for (let i = 0; i < newVal.documentFullResponseDto.length; i++) {
          const document = newVal.documentFullResponseDto[i];
          const documentType = document.documentType;
          for (let j = 0; j < document.documentPhotosResponseDtos.length; j++) {
            const photo = document.documentPhotosResponseDtos[j];
            const image = {
              src: photo.url,
              title: photo.fileName,
              documentId: document.documentId,
              photoId: photo.photoId,
              documentType: document.documentType,
              expiryDate: document.expiryDate,
            };
            let imageArray;
            if (documentType === 'Passport') {
              imageArray = this.imagePassport;
            } else if (documentType === 'ResidencePermit') {
              imageArray = this.imageResidencePermit;
            } else if (documentType === 'SocialNumber') {
              imageArray = this.imageSocialNumber;
            }
            const index = imageArray.findIndex(
              (existingImage) => {
                return existingImage.photoId === photo.photoId
               && existingImage.documentId === document.documentId
              },
            );
            if (index === -1) {
              imageArray.push(image);
            } else {
              // Replace the existing image object with the new one
              imageArray.splice(index, 1, image);
            }
          }
        }
        // access the arrays based on documentType
      },
    },
  },
  mounted() {
    this.$parent.$on('reset-images', this.resetImages);
  },
  beforeDestroy() {
    this.$parent.$off('reset-images', this.resetImages);
  },

  methods: {
    ...mapActions([
      'loadCountryStates',
      'deleteImage',
      'fullUserById',
    ]),
    resetImages() {
      this.imagePassport = [];
      this.imageSocialNumber = [];
      this.imageResidencePermit = [];
    },
    downloadFile(fileUrl) {
      // Extract filename from the URL
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      console.log('aa', fileName)
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      console.log('b', link)
      console.log('c', fileUrl)
      console.log('d', fileName)



      // Trigger a click on the anchor element to start the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openLightbox(index) {
      this.lightboxIndex = index;
      this.lightboxVisible = true;
    },
    closeLightbox() {
      this.lightboxVisible = false;
    },
    openLightbox1(index) {
      this.lightboxIndex1 = index;
      this.lightboxVisible1 = true;
    },
    closeLightbox1() {
      this.lightboxVisible1 = false;
    },
    openLightbox2(index) {
      this.lightboxIndex2 = index;
      this.lightboxVisible2 = true;
    },
    closeLightbox2() {
      this.lightboxVisible2 = false;
    },
    handleHide() {
      this.visible = false
    },
    onCancel() {
      this.$refs.modal.hide();
      this.imagePassport = []
      this.imageResidencePermit = []
      this.imageSocialNumber = []
    },
    show() {
      this.visible = true
    },
  },
};
</script>

<style lang="scss" scoped>
.files {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    background-color: rgb(207, 207, 207);
    border: none;
    box-shadow: none;
    padding: 12px;
    color: #212529;
    border-radius: 8px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
 .photo{
    margin-left: 5px;
    height: 50px;
    border-radius: 8px;
  }
.second-photo{
  margin-left: 20px;
}
.hovered {
  /* Additional styles for the hovered photo */
  transform: scale(7.2);
  border-radius: 0px;
  z-index: 10;
}
.third-photo{
  margin-left: 20px;
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: flex-end;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons{
  display: flex;
  align-items:flex-end;
}
</style>
